video, canvas {
    max-width: 100%;
    /* filter: grayscale(1) invert(0) contrast(5) brightness(5); */
}

video {
    border: 3px solid #E6E6E6;
    border-radius: 13px;
}

.video-inverted {
    max-width: 100%;
    filter: grayscale(1) invert(1) contrast(5) brightness(5);
}

.video-normal {
    max-width: 100%;
    filter: grayscale(1) invert(0) contrast(5) brightness(5);
}

.custom-switch .custom-control-label::before, 
.custom-switch .custom-control-label::after {
  width: 40px; /* adjust these values as needed */
  height: 20px;
}

.custom-switch .custom-control-label::after {
  width: 18px;
  top: 1px;
}


