.mash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.mash {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82vw;
  height: auto;
  background-color: #E6E6E6;
}

.mash-image {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

.preview-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 82vw;
}

.preview {
    width: 9em;
    border: 3px solid #E6E6E6;
    padding: 5px;
    background-color: #AEAEAE;
    border-radius: 18px;
    max-height: 9em;
    overflow: hidden;
    aspect-ratio: 1 / 1;
}

.preview-image {
  width: 100%;
  height: auto;
  cursor: pointer;
  /* max-height: 9em;
  max-width: 9em; */
  padding: 0.33em;
}

#worksp-row-top {
    min-height: 3vh;
}

#worksp-row-mid {
    min-height: 12vh;
}

#worksp-row-bot {
    min-height: 60vh;
    padding: 1.5em 0;
}

#worksp-row-top,
#worksp-row-mid {
    margin: 0 0 1.5em 0;
}

.menu__button {
    background-color: none;
    /* margin-left: auto;
    margin-right: auto; */
}

.mobile-nav {
    display: none;
}

.header-nav {
    border-bottom: 3px solid #CC2727 !important;
    box-shadow: 0px 1px 15px #E6E6E6 !important;
}

.ril-image-current {
    background-color: #E6E6E6;
}

.nav-tabs .nav-item {
    text-align: center;
    text-transform: uppercase;
}

.custom-card {
    background-color: #2B2B2B;
    /* padding: 10px !important; */
    border-radius: 13px;
    border: 3px solid #E6E6E6;
    box-shadow: 0px 1px 10px #CC2727 !important;
}

.hero-logo-main {
        height: calc(100vh - 85px);
        zIndex: 1;
    }

.img-tooltip-l {
    float: left;
}

.img-tooltip-r {
    float: right;
}

.img-tooltip-l,
.img-tooltip-r {
    font-size: 0.85em;
    text-transform: lowercase;
    color: #cc2727;
    font-weight: bold;
}

.top3-tile {
    border-radius: 13px;
    border: 3px solid #E6E6E6;
}

.image-item {
    max-width: 20vw;
    float: left;
}

.gallery-image {
    width: 100%;
    padding: 0.5em 1em;
}

.polaroid {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 13px; /* 5px rounded corners */
  
}

.gallery-image-card {
  border: 3px solid #E6E6E6;
  border-radius: 13px;
  max-height: 50vh;
}

.polaroid:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.nav-text {
    text-transform: uppercase;
    margin-right: 1em;
}

.hide-desktop {
    display: none !important; 
}

.txt-spc1 {
    text-spacing: 1em;
}

.txt-bold {
    font-weight: bold !important;
}

.txt-good {
    color: green;
}

#mash-regular {
    display: flex;
    align-items: center;
    justify-content: center;
}

.scores-tip {
    font-size: 0.675em;
}

/* .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: transparent !important;
    max-height: 60vh;
    max-width: 60vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
}

.ReactModal__Content.ReactModal__Content--after-open {
    background-color: #0b0b0b !important;
    border: 3px solid #AEAEAE !important;
    overflow: hidden !important;
} */

.amHome2-1st {
    min-height: 60vh;
}

.amHome2-2nd {
    min-height: 50vh;
}

.amHome2-3rd {
    min-height: 45vh;
}

.amHome2-4th {
    min-height: 40vh;
}

.profile-text {
    font-size: 1.25em;
}

@media (max-width: 768px) {
    .hide-mobile {
        display: none;
    }

    .hide-desktop {
        display: flex !important; 
    }

    .hero-logo-main {
        height: calc(100vh - 160px);
        zIndex: 1;
    }
    
    .mobile-nav {
        display: block;
    }
    
    .navbar-light .navbar-nav .nav-link {
        margin: 0 0.6em;
    }
    
    .mobile-nav-button {
        font-size: 1.75em;
    }
    
    .header-nav ~ a {
        font-size: 1em; 
    }
    
    .header-nav {
        /* position: fixed; */
        font-size: 1em;
    }
    
    #worksp-row-top {
        min-height: 3vh;
    }

    #worksp-row-mid {
        min-height: 12vh;
    }

    #worksp-row-bot {
        min-height: 44vh;
        padding: 0.5em 0;
        max-height: 50vh;
    }
    
    #worksp-row-top,
    #worksp-row-mid {
        margin: 0.5em 0;   
    }
    
    #basic-navbar-nav {
        text-align: right;
    }

    .amHome2-1st {
        min-height: 65vh;
    }

    .amHome2-2nd {
        min-height: 55vh;
    }

    .amHome2-3rd {
        min-height: 45vh;
    }

    .amHome2-4th {
        min-height: 35vh;
    }
}