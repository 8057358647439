/* CSS Document */

body {
	background-color: #0B0B0B;
	color: #F6F6F6;
	font-family: 'Quicksand', sans-serif;
	font-size: 0.85em;
}

#profile-pic {
	background-color: #0B0B0B;
	border-radius: 100px;
	border: 3px solid #CC2727;
}

#mash-workspace,
.global-top-imgs,
.global-top-msks,
.ar-square {
	aspect-ratio: 1/1;
}

.firebaseui-container {
	z-index: 9999 !important;
	background-color: transparent !important;
}

#background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.box-br-top {
	border-top-left-radius: 25px;
    border-top-right-radius: 25px;
	border-top: 3px solid #CC2727;
}

.box-br-bottom {
	border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
	border-bottom: 3px solid #CC2727;
}

.box-shadow-crimson {
	/* box-shadow: 3px 5px #CC2727; */
	border-right: 3px solid #CC2727;
    border-left: 3px solid #CC2727;
}

.global-top-imgs,
.global-top-msks {
	overflow: hidden;
    border: 2px solid #DEDEDE;
    border-radius: 5px;
}

.bg-vampire {
	background-color: #0B0B0B;
}

.bg-charleston {
	background-color: #2B2B2B;
}

.bg-cultured {
	background-color: #F6F6F6;
}

.bg-gainsboro {
	background-color: #DEDEDE;
}

.bg-silverfoil {
	background-color: #AEAEAE;
}

.bg-rrcrimson {
	background-color: #CC2727;
}

.bg-transparent {
	background-color: transparent;
}


/* TEXT COLOR */
.txt-vampire {
	color: #0B0B0B;
}

.txt-charleston {
	color: #2B2B2B;
}

.txt-cultured {
	color: #F6F6F6;
}

.txt-gainsboro {
	color: #DEDEDE;
}

.txt-silverfoil {
	color: #AEAEAE;
}

.txt-crimson {
	color: #CC2727;
}

.img-plh {
	background-color: #AEAEAE;
    overflow: hidden;
    border: 2px solid #DEDEDE;
    border-radius: 5px;
}

.msk-plh {
	background-color: #AEAEAE;
    overflow: hidden;
    border: 2px solid #DEDEDE;
    border-radius: 5px;
}

.gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}
.img-row {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.img-container {
  border: 0px solid transparent;
  overflow: hidden; /* This will hide the parts of the image that exceed the div's dimensions */
	aspect-ratio: 1/1;
    margin-bottom: 5px;
}
.top-img {
	position: absolute;
	top: 0;
	left: 0;
}
.top-mask {
	position: absolute;
	top: 0;
	right: 0;
}