.react-images__view {
    max-width: 82vw !important;
    max-height: 82vh !important;
}

.react-images__view img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
